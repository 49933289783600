<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <!--

      Revisar por qué no aparece android

      <ion-header collapse="condense">
        <ion-toolbar color="primary">
          <ion-title size="large">Lotes</ion-title>
        </ion-toolbar>
      </ion-header> -->

      <div id="container">
        <ion-card v-if="lotes.length === 0 && delLotes.length === 0" class="ion-margin-top" color="light">
          <ion-row class="ion-align-items-center">
            <ion-col v-if="isAdmin && idProductor">
              <ion-text class="negrita ion-text-capitalize" style="margin-left: 3%">
                {{ "Productor: " + nombreProductor }}
              </ion-text>
            </ion-col>
            <ion-col size="auto" class="ion-float-end">
              <div style="margin-left:auto; margin-top:5px">
                <ion-button fill="outline" color="medium" @click="goToMapa()">
                  Mapa
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-end">
            <ion-button fill="clear" size="small" color="tertiary"
              style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
              @click="goToEstablecimientos()">
              {{
                isAdmin && idProductor
                ? "Establecimientos"
                : "Mis Establecimientos"
              }}
            </ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-center ion-margin-top ion-margin-bottom">
            <ion-button size="large" @click="createModal">
              Agregar Lote
            </ion-button>
          </ion-row>
          <ion-row class="ion-justify-content-end ion-margin-top">
            <ion-button fill="clear" size="small" color="tertiary"
              style="margin-inline: 0px; margin-top: 0px; margin-bottom: 8px; font-size: 11.5px;"
              @click="downloadExcelTemplate()">
              Planilla de Registro Alternativo
            </ion-button>
          </ion-row>
        </ion-card>

        <ion-card class="ion-margin-top" color="light" v-if="lotes.length !== 0 || delLotes.length !== 0">
          <ion-card-header style="padding-bottom: 4px">
            <ion-row>
              <ion-text class="negrita ion-align-self-center ion-text-capitalize">
                {{
                  isAdmin && idProductor
                  ? "Lotes de " + nombreProductor
                  : "Mis Lotes"
                }}
              </ion-text>
              <ion-fab-button @click="createModal" size="small" class="ion-margin-left">
                <ion-icon color="light" :md="icons.add" :ios="icons.add"></ion-icon>
              </ion-fab-button>
              <div style="margin-left:auto; margin-top:5px">
                <ion-button fill="outline" color="medium" @click="goToMapa()">
                  Mapa
                </ion-button>
              </div>
            </ion-row>
            <ion-row class="ion-justify-content-end">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="goToEstablecimientos()">
                {{
                  isAdmin && idProductor
                  ? "Establecimientos"
                  : "Mis Establecimientos"
                }}
              </ion-button>
            </ion-row>
          </ion-card-header>

          <ion-card-content>
            <ion-list lines="full" v-for="est in establecimientos" :key="est._id"
              style="padding-top: 0px; padding-bottom: 0px;">
              <ion-item-group>
                <ion-item-divider class="ion-text-capitalize"
                  style="font-weight: bold; font-size: 18px; padding-top: 12px;" color="light">
                  <ion-row class="ion-align-items-center">
                    <ion-text style="margin-right: 16px">
                      {{ est.nombre }}
                    </ion-text>
                    <ion-button fill="outline" color="medium" @click="goToLluvia(est)" v-if="est._id !== '0'">
                      <ion-icon :md="icons.rain" :ios="icons.rain" color="secondary"></ion-icon>
                      <!--                     <ion-thumbnail>
                      <ion-img
                        src="assets/icon/fichaLote/icoRiego.png"
                      />
                    </ion-thumbnail> -->
                    </ion-button>
                  </ion-row>
                </ion-item-divider>
                <div v-for="lote in lotes" :key="lote._id">
                  <ion-item button="true" type="button" @click="goTo(lote)" class="itemBorder" v-if="(lote.establecimiento &&
                    lote.establecimiento._id === est._id) ||
                    (!lote.establecimiento && est._id === '0')
                    ">
                    <ion-row>
                      <ion-col size="auto">
                        <ion-thumbnail>
                          <ion-img src="assets/icon/agcamp.jpg" />
                        </ion-thumbnail>
                      </ion-col>
                      <!-- <div class="ion-text-start margen8pxArriba"> -->
                      <ion-col class="ion-text-left ">
                        <ion-text style="font-weight: bold">
                          {{ lote.nombre }}
                        </ion-text>
                        <br />
                        <ion-text class="">
                          {{ lote.localidad }}
                        </ion-text>
                      </ion-col>
                      <!-- </div> -->
                    </ion-row>
                  </ion-item>
                </div>
              </ion-item-group>
            </ion-list>
            <ion-row class="ion-justify-content-end ion-margin-top" v-if="isAdminAapresid">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="modalImport = true">
                Importar Datos de Lotes
              </ion-button>
            </ion-row>
            <ion-row class="ion-justify-content-end" v-if="lotes && lotes.length">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="modalExcel = true">
                Exportar Datos de Lotes
              </ion-button>
            </ion-row>
            <ion-row class="ion-justify-content-end ion-margin-top">
              <!--
              v-if="persona._id === user._id"
              Esto va en el if de la row para cuando vuelvan a pedir de activarlo
              -->
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="downloadExcelTemplate()">
                Planilla de Registro Alternativo
              </ion-button>
            </ion-row>
            <ion-button fill="clear" style="white-space:normal" color="tertiary" class="ion-margin-top"
              @click="displayDelLotes = !displayDelLotes" v-if="delLotes.length">
              {{ "Lotes Desactivados v" }}
            </ion-button>
            <div v-if="displayDelLotes">
              <ion-list lines="full" v-for="lote in delLotes" :key="lote._id">
                <ion-item class="itemBorder">
                  <ion-grid fixed>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <ion-text class="negrita" style="font-weight: bold">
                          {{ lote.nombre }}
                        </ion-text>
                        <br />
                        <ion-text>
                          {{ lote.localidad }}
                        </ion-text>
                      </ion-col>
                      <ion-col size="auto">
                        <ion-button fill="clear" size="small" color="dark" class="ion-float-right"
                          @click="reactivateLote(lote)">
                          <ion-icon :md="icons.reload" :ios="icons.reload" size="large"></ion-icon>
                        </ion-button>
                      </ion-col>
                      <!-- </div> -->
                    </ion-row>
                  </ion-grid>
                </ion-item>
              </ion-list>
            </div>
          </ion-card-content>
        </ion-card>
        <ion-modal id="modal" :is-open="modalEdit" @didDismiss="modalEdit = false" :backdropDismiss="false">
          <LoteForm :lote="selectedLote" :error="error" :persona="persona" @cancel="modalEdit = false"
            @submit="submitCreateOrEdit" @goToMapa="goToMapa" class="modal-wrapper" />
        </ion-modal>
        <ion-modal id="modal" :is-open="modalImport" @didDismiss="modalImport = false" :backdropDismiss="false">
          <ion-card>
            <ion-card-header>Importar Datos de Lotes</ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label :position="selectedCampania ? 'stacked' : 'floating'">
                  Campaña
                </ion-label>
                <ion-select :value="selectedCampania" :selected-text="selectedCampania && selectedCampania.nombre
                  ? selectedCampania.esActual
                    ? '*' + selectedCampania.nombre.toUpperCase()
                    : selectedCampania.nombre
                  : undefined
                  " @ionChange="selectedCampania = $event.target.value">
                  <ion-select-option v-for="camp in campanias" :value="camp" :key="camp._id">
                    {{
                      camp.esActual
                      ? "*" + camp.nombre.toUpperCase()
                      : camp.nombre
                    }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-label :position="selectedOrigen ? 'stacked' : 'floating'">
                  Origen
                </ion-label>
                <ion-select :value="selectedOrigen" :selected-text="selectedOrigen && selectedOrigen.nombre
                  ? selectedOrigen.nombre
                  : undefined
                  " @ionChange="selectedOrigen = $event.target.value">
                  <ion-select-option v-for="orig in origenes" :value="orig" :key="orig._id">
                    {{ orig.nombre }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-row class="ion-align-items-center">
                <ion-col size="auto">
                  <ion-button color="tertiary" @click="fileInput.click()">Subir Archivo</ion-button>
                </ion-col>
                <ion-col>
                  <ion-text style="white-space:normal">{{ file && file.name ? file.name : "-" }}</ion-text>
                </ion-col>
              </ion-row>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button fill="outline" class="ion-margin-top" color="medium" @click="modalImport = false">
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button class="ion-float-right ion-margin-top" @click="importExcel()">
                    Importar
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-modal>
        <ion-modal id="modal" :is-open="modalExcel" @didDismiss="modalExcel = false" :backdropDismiss="false">
          <ion-card>
            <ion-card-header>Exportar Datos de Lotes</ion-card-header>
            <ion-card-content>
              <ion-item>
                <ion-label :position="selectedCampania ? 'stacked' : 'floating'">
                  Campaña
                </ion-label>
                <ion-select :value="selectedCampania" :selected-text="selectedCampania && selectedCampania.nombre
                  ? selectedCampania.esActual
                    ? '*' + selectedCampania.nombre.toUpperCase()
                    : selectedCampania.nombre
                  : undefined
                  " @ionChange="selectedCampania = $event.target.value">
                  <ion-select-option v-for="camp in campanias" :value="camp" :key="camp._id">
                    {{
                      camp.esActual
                      ? "*" + camp.nombre.toUpperCase()
                      : camp.nombre
                    }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-row class="ion-float-right">
                <ion-col>
                  <ion-button fill="outline" class="ion-margin-top" color="medium" @click="modalExcel = false">
                    Cancelar
                  </ion-button>
                </ion-col>
                <ion-col>
                  <ion-button class="ion-float-right ion-margin-top" @click="showFilas()">
                    Exportar
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
          </ion-card>
        </ion-modal>
        <div v-if="isAdmin && idProductor" style="margin-bottom: 25%">
          <ion-button @click="volver" class="ion-margin-top ion-float-right ion-margin-bottom" style="margin-right:16px">
            Volver
          </ion-button>
        </div>
        <ion-modal id="modal1" :is-open="modalProgressBar" @didDismiss="modalProgressBar = false"
          :backdropDismiss="false">
          <ion-card>
            <ion-card-header>
              Se esta generando el archivo de Excel. Por favor espere...
            </ion-card-header>
            <ion-card-content>
              <ion-progress-bar :value="progress"></ion-progress-bar>
            </ion-card-content>
          </ion-card>
        </ion-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonItemGroup,
  IonItemDivider,
  IonLabel,
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonCol,
  IonContent,
  IonList,
  IonPage,
  IonIcon,
  IonRow,
  IonFabButton,
  IonButton,
  alertController,
  IonThumbnail,
  IonImg,
  IonModal,
  IonProgressBar
} from "@ionic/vue";
import LoteForm from "../../components/LoteForm.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { ref } from 'vue';
import http from "@/api/http";
import { Lotes, Persona, Establecimientos, Campanias } from "@/api";
import { addOutline, reloadOutline, rainyOutline } from "ionicons/icons";
import dateFormat from "@/utils/dateFormat";
import capitalizeWords from "@/utils/capitalizeWords.js";
import readXlsFile from "read-excel-file"

export default {
  components: {
    IonContent,
    IonText,
    IonLabel,
    IonItem,
    IonItemGroup,
    IonItemDivider,
    IonFabButton,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonList,
    IonButton,
    IonPage,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonRow,
    IonCol,
    IonThumbnail,
    IonModal,
    IonImg,
    LoteForm,
    IonProgressBar
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    modalEdit: false,
    modalDelete: false,
    modalExcel: false,
    modalProgressBar: false,
    modalImport: false,
    seleccionEquivalencias: false,
    success: null,
    error: "",
    progress: ref(0),
    lotes: {},
    productor: {},
    nombreProductor: "",
    selectedLote: {},
    icons: {
      add: addOutline,
      reload: reloadOutline,
      rain: rainyOutline
    },
    selectedCampania: {},
    campaniaTxt: "",
    selectedOrigen: {},
    origenes: [],
    campanias: [],
    establecimientos: [],
    establecimientosRealesCount: 0,
    delLotes: [],
    selectedDelLote: {},
    displayDelLotes: false,
    file: {},
    fileContent: {},
    fileInput: {},
    xlsType: "application/vnd.ms-excel",
    xlsxType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  }),
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    ...mapGetters(["isAdminRegional"]),
    ...mapState(["dataPasada"]),
    ...mapActions(["mandarData"]),
    idProductor() {
      return this.$route.params.idProductor;
    },
    isAdminAapresid() {
      return this.isAdmin && !this.isAdminRegional;
    },
    persona() {
      if (this.isAdmin && this.idProductor) {
        if (this.productor && this.productor._id) {
          return this.productor;
        }
        return {};
      }
      return this.user;
    }
  },

  watch: {
    async idProductor() {
      await this.dataSync();
    },
    modalImport(val) {
      if (val === false) {
        this.selectedCampania = {};
        this.selectedOrigen = {};
        this.file = {};
        this.fileContent = {};
        this.fileInput = document.createElement('input');
        this.fileInput.type = 'file';
        this.fileInput.accept = this.xlsType + "," + this.xlsxType;
        this.fileInput.onchange = async (e) => {
          this.file = e.target.files[0];
          this.fileContent = await readXlsFile(e.target.files[0]);
        };
      } else {
        this.selectedCampania = this.campanias.filter(
          c => c.esActual === true
        )[0];
      }
    },
    modalExcel(val) {
      if (val === false) {
        this.selectedCampania = {};
      } else {
        this.selectedCampania = this.campanias.filter(
          c => c.esActual === true
        )[0];
      }
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
    this.fileInput = document.createElement('input');
    this.fileInput.type = 'file';
    this.fileInput.accept = this.xlsType + "," + this.xlsxType;
    this.fileInput.onchange = async (e) => {
      this.file = e.target.files[0];
      this.fileContent = await readXlsFile(e.target.files[0]);
    };
    setInterval(() => {
      if (this.modalProgressBar) {
        if (this.progress <= 0.25) {
          this.progress += 0.01;
        } else {
          setInterval(() => {
            if (this.progress <= 0.75) {
              this.progress += 0.01;
            }
          }, 7000);
        }
      }
    }, 300);
  },

  methods: {
    ...mapActions(["getMe"]),
    async dataSync() {
      if (this.idProductor && this.isAdmin) {
        this.productor = await this.getProductor(this.idProductor);
        this.nombreProductor = this.productor.display_name;
      }
      await this.getLotes();
      this.establecimientos = this.getEstablecimientos();
      this.campanias = await this.getCampanias();
      this.getOrigenes();
      this.sortLists();
      this.getEstablecimientosRealesCount();
    },

    async editModal(lote) {
      this.error = "";
      this.selectedLote = lote;
      this.modalEdit = true;
    },

    async createModal() {
      this.error = "";
      this.selectedLote = {};
      this.modalEdit = true;
    },

    deleteModal(lote) {
      this.selectedLote = lote;
      this.modalDelete = true;
    },

    async getLotes() {
      const pers = this.persona;
      if (!pers._id) {
        return;
      }
      const query = { persona: pers._id };

      const { data, error } = await Lotes.get({
        query: query,
        populate: "persona, establecimiento",
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lotes.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      this.lotes = data.filter(lote => lote.deleted === false);
      this.delLotes = data.filter(lote => lote.deleted === true);
    },

    getEstablecimientos() {
      let establecimientos = this.lotes.map(lote => lote.establecimiento);
      establecimientos = establecimientos.map(establecimiento => {
        if (!establecimiento) {
          return { _id: "0", nombre: "Sin establecimiento" };
        }
        return { _id: establecimiento._id, nombre: establecimiento.nombre };
      });
      return this.removeDuplicatesFromArray(establecimientos);
    },

    async getEstablecimientosRealesCount() {
      const pers = this.persona;
      if (!pers._id) {
        return;
      }
      const query = { persona: pers._id, deleted: false };

      const { data, error } = await Establecimientos.get({
        query: query,
        sort: { updatedAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Establecimientos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      this.establecimientosRealesCount = data.length;
    },

    sortLists() {
      this.lotes.sort(function (a, b) {
        return a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase());
      });
      this.delLotes.sort(function (a, b) {
        return a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase());
      });
      this.establecimientos.sort(function (a, b) {
        return a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase());
      });
      const sinEst = this.establecimientos.find(est => est._id === "0");
      if (sinEst) {
        this.establecimientos.splice(this.establecimientos.indexOf(sinEst), 1);
        this.establecimientos.push(sinEst);
      }
    },

    goToMapa() {
      /*if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-mapa-productor",
          params: { idProductor: this.idProductor }
        });
      } else {*/
      this.modalEdit = false;
      this.$router.push({ name: "persona-mapa" });
      //}
    },

    goToEstablecimientos() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({ name: "admin-establecimientos-productor" });
      } else {
        this.$router.push({ name: "persona-establecimientos" });
      }
    },

    goTo(item) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-lote",
          params: {
            idProductor: this.idProductor,
            tab: "Sl",
            idLote: item._id
          }
        });
      } else {
        this.$router.push({
          name: "lote",
          params: {
            tab: "Sl",
            idLote: item._id
          }
        });
      }
      //window.location.reload();
    },

    goToLluvia(est) {
      this.$router.push({
        name: "establecimiento-lluvia",
        params: {
          idEstablecimiento: est._id
        }
      });
    },

    goToLotesEquivalencias(error) {
      this.$store.dispatch('mandarData', { error, archivo: this.file.name, origen: this.selectedOrigen, rutaAnterior: this.$route.path, idProductor: this.idProductor });
      this.$router.push({ name: "persona-lotes-equivalencias" });
    },

    async getProductor(idProductor) {
      const { data, error } = await Persona.getBy_id(idProductor);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productor.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getCampanias() {
      const { data, error } = await Campanias.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Campañas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },
    async getOrigenes() {
      try {
        const { data } = await http.get("/api/get-origenes");
        this.origenes = data;
      } catch (error) {
        console.log(error);
      }
    },
    async reactivateLote(lote) {
      const alert = await alertController.create({
        header: "Lotes",
        message: "¿Confirma Reactivación del Lote?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              lote.deleted = false;
              const { error } = await Lotes.save(lote);
              if (!error) {
                const alert = await alertController.create({
                  header: "Lotes",
                  message: "Lote Reactivado",
                  buttons: [
                    {
                      text: "Aceptar",
                      handler: async () => {
                        await this.dataSync();
                      }
                    }
                  ]
                });
                alert.present();
              }
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async showFilas() {
      const data = await http.get("/api/get-filas-xls", {
        params: {
          idPersona: this.persona._id,
          idCampania: this.selectedCampania._id,
          nombreEmail: undefined,
          regional: undefined
        }
      });
      this.campaniaTxt = this.selectedCampania
        ? this.selectedCampania.nombre + "_"
        : "";
      const alert = await alertController.create({
        header: "Exportar datos",
        message: `${data.message}\n ¿Confirma la exportacion?`,
        buttons: [
          {
            text: "Si",
            handler: async () => {
              this.modalExcel = false;
              this.modalProgressBar = true;
              await this.generateExcel(data.data);
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async generateExcel(id) {
      const { data } = await http.get("/api/get-xls-lotes-persona", {
        params: {
          id
        }
      });
      this.progress = 1;
      const mimeType = this.xlsxType;
      const fileName =
        "Informe_Lotes_" +
        capitalizeWords(this.persona.apellido) +
        "_" +
        capitalizeWords(this.persona.nombre) +
        "_" +
        this.campaniaTxt +
        "_" +
        dateFormat(new Date(), "dd-MM-yyyy") +
        "_-_" +
        dateFormat(new Date(), "HH-mm") +
        ".xlsx";
      fetch(`data:${mimeType};base64,${data}`)
        .then(res => res.blob())
        .then(blob => new File([blob], fileName, { type: mimeType }))
        .then(doc => {
          const url = (window.URL || window.webkitURL).createObjectURL(doc);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          return url;
        });
      this.progress = 0;
      this.modalProgressBar = false;
    },

    beautifyErrors(errors) {
      const errorList = JSON.parse(errors);
      let beautifiedErrors = "";
      for (const item of errorList) {
        beautifiedErrors += `Errores en fila ${item.fila}, sección ${item.seccion}:\n`;
        for (const error of item.errores) {
          beautifiedErrors += `- ${error.message}\n`;
        }
        beautifiedErrors += `\n`
      }
      return beautifiedErrors;
    },

    beautifyErrorsHTML(errors) {
      const errorList = JSON.parse(errors);
      let beautifiedErrors = "";
      for (const item of errorList) {
        beautifiedErrors += `<li><strong>Errores en fila ${item.fila}, sección ${item.seccion}:</strong><ul>`;
        for (const error of item.errores) {
          beautifiedErrors += `<li>${error.message}</li>`;
        }
        beautifiedErrors += "</ul></li>";
      }
      return beautifiedErrors;
    },

    generateErrorTxt(data) {
      const mimeType = "text/javascript";
      const fileName =
        "Errores_Importacion_" +
        dateFormat(new Date(), "dd-MM-yyyy") +
        "_-_" +
        dateFormat(new Date(), "HH-mm") +
        ".txt";
      // eslint-disable-next-line no-undef
      try {
        fetch(`data:${mimeType};JSON,${this.beautifyErrors(data.error)}`)
          .then(res => res.blob())
          .then(blob => new File([blob], fileName, { type: mimeType }))
          .then(doc => {
            const url = (window.URL || window.webkitURL).createObjectURL(doc);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
            document.body.appendChild(a);
            a.click();
            a.addEventListener('error', (err) => {
              console.error('Error durante la descarga:', err);
            });
            a.addEventListener('load', () => {
              a.remove();
            });
            return url;
          });
      } catch (error) {
        console.error('Error durante la creación del archivo:', error);
      }
    },

    async importExcel() {
      if (Object.keys(this.selectedOrigen).length === 0) {
        const alert = await alertController.create({
          header: "Debe seleccionar un Origen",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (this.file.name !== undefined) {
        if (this.file.type === this.xlsType || this.file.type === this.xlsxType) {
          if (this.fileContent.length !== 0) {
            const postData = {
              fileType: this.file.type,
              fileContent: this.fileContent,
              campania: this.selectedCampania,
              originImport: this.selectedOrigen._id,
              user: this.persona,
              profileId: this.idProductor
            };
            try {
              await http.post('/api/post-xls-lotes-persona', postData);
              const alert = await alertController.create({
                header: "Importación de datos",
                message: "Se han cargando los datos del archivo con exito",
                buttons: [
                  {
                    text: "Aceptar"
                  }
                ]
              });
              this.file = {};
              this.fileContent = {};
              await this.dataSync();
              this.modalImport = false;
              return alert.present();
            } catch (error) {
              if (error.status === 500) {
                const alert = await alertController.create({
                  header: "Error con el archivo",
                  message: error.error,
                  buttons: [
                    {
                      text: "Aceptar"
                    }
                  ]
                });
                return alert.present();
              }
              else {
                const alert = await alertController.create({
                  header: "Error de validación de datos",
                  message: "Los datos ingresados no son correctos o están incompletos",
                  buttons: [
                    {
                      text: "Detalles",
                      handler: async () => {
                        this.goToLotesEquivalencias(error);
                      }
                    },
                    {
                      text: "Aceptar"
                    }
                  ]
                });
                return alert.present();
              }
            }
          } else {
            const alert = await alertController.create({
              header: "Error de verificación de archivos",
              message: "No se pudo recuperar el contenido del archivo",
              buttons: [
                {
                  text: "Aceptar"
                }
              ]
            });
            this.file = {};
            this.fileContent = {};
            return alert.present();
          }
        } else {
          const alert = await alertController.create({
            header: "Error de validación de archivos",
            message: "El tipo de archivo es incorrecto. Tiene que ser .xls o xlsx",
            buttons: [
              {
                text: "Aceptar"
              }
            ]
          });
          this.file = {};
          this.fileContent = {};
          return alert.present();
        }
      } else {
        const alert = await alertController.create({
          header: "Error de validación de archivos",
          message: "No se ha seleccionado ningún archivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        this.file = {};
        this.fileContent = {};
        return alert.present();
      }
    },

    async downloadExcelTemplate() {
      const { data } = await http.get("/api/get-xls-template");
      const mimeType = this.xlsxType;
      const fileName = "Planilla de Registro Alternativa.xlsx";
      fetch(`data:${mimeType};base64,${data}`)
        .then(res => res.blob())
        .then(blob => new File([blob], fileName, { type: mimeType }))
        .then(doc => {
          const url = (window.URL || window.webkitURL).createObjectURL(doc);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          return url;
        });
    },

    removeDuplicatesFromArray(arr) {
      const unique = arr.filter((obj, index, self) => {
        return self.findIndex(est => est._id === obj._id) === index;
      });
      return unique;
    },

    async submitCreateOrEdit({ lote }) {
      this.error = "";
      const { data, error } = await Lotes.save(lote);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Lotes",
          message: lote._id ? "Lote Actualizado" : "Lote Creado",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                if (this.isAdmin && this.idProductor) {
                  this.$router.push({
                    name: "admin-productor-lote",
                    params: {
                      idProductor: this.idProductor,
                      tab: "Sl",
                      idLote: data._id
                    }
                  });
                } else {
                  this.$router.push({
                    name: "lote",
                    params: {
                      tab: "Sl",
                      idLote: data._id
                    }
                  });
                }
              }
            }
          ]
        });
        this.modalEdit = false;
        alert.present();
      }
    },

    volver() {
      this.$router.push({ name: "admin-productores" });
    },
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  color: black;
  font-size: 125%;
  margin-left: 0px;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}

ion-card {
  margin-top: 16px;
}
</style>
